<template>
    <!-- Personal Profile View -->
    <div class="page-layout ml-2 mr-3">
        <!-- <div class="row" v-if="ajax_call_in_progress">
            <div class="col-lg-12 py-5 px-3 mt-10">
                <div class="flex-container">
                    <half-circle-spinner :animation-duration="1000" :size="60" color="#00448b" />
                </div>
            </div>
        </div> -->
        <div class="pb-10">
            <div class="d-flex flex-row flex-wrap justify-content-between align-items-center">
                <div class="breadcrumbactive">
                    <div>
                        <span class="pointer" @click="goToListView">List View</span>
                        <span class="pointer" v-if="$route.query.outlet_view == 'view'" @click="goToCompanyInfo">Company</span>
                        <span class="pointer" v-if="$route.query.bankdetails_view == 'view'" @click="goToCompanyInfo">Company</span>
                        <span class="pointer" v-if="organization_for_company != ''">{{organization_for_company}}</span>
                        <div v-if="toShow == 'headquarters'">View Headquarters</div>
                        <span v-if="toShow == 'company_info'">View Company</span>
                        <div v-if="toShow == 'outlets'">View Outlets</div>
                        <div v-else-if="toShow == 'bankdetails'">View Bank Details</div>
                    </div>
                </div>
                <!-- <div v-if="toShow == 'company_info'" class="justify-content-end position-relative d-flex">
                    <button v-if="!enable" class="btn btn-icon pointer ml-2 mr-2 text-capitalize" @click="enableEdit()" style="padding: 3px 8px !important;background: #00448b;border-radius: 3px !important;">
                        <i class="icon icon-pencil text-secondary pointer fs-16" style="color: #fff !important;"></i>
                        <span class="mx-1" style="color: #fff;font-size: 14px;font-weight: 600;">Edit</span>
                    </button>
                </div> -->
                <!-- <div v-if="toShow == 'headquarters'">
                    <div class="d-flex align-items-center">
                        <button class="btn btn-new-primary" @click="showCreateBranch">+ Add Branch</button>
                    </div>
                </div> -->
            </div>
            <div class="profile-block">
                <div class="profile-block-container pb-3"
                    style="border-radius: 15px;">
                    <!-- <div class="left-sec left-sec-company">
                        <div class=""
                            style="height:100% !important;">
                            <div class="cover-card">
                                <div class="cover-pic row ml-0 mr-0 bg-white">
                                    <img class="ml-auto mr-auto" height="180px"
                                        :src="comp_data.cover_image || 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/coverpic3.jpg'"
                                        alt="Cover Picture" style="height: 180px; width: fit-content;" />
                                    <div v-if="this.loggedInUser.user_role == 'owner' || this.loggedInUser.user_role == 'super admin' || this.loggedInUser.user_role == 'super_admin'" class="profile-cover-pic-change">
                                        <div class="change-cover-pic d-flex align-items-center pointer">
                                            <i class="icon icon-picture mr-1 text-white" style="font-size: 20px;"></i>
                                            <label for="input_cover_pic">Edit Photo</label>
                                            <input id="input_cover_pic" class="v-hidden w-0" type="file" name="input_cover_pic" @change="uploadCoverAttachment" accept="image/*" />
                                        </div>
                                    </div>
                                </div>
                                <div class="cover-card-block">
                                    <div class="change-pic px-5">
                                        <div style="position: relative;">
                                            <img style="border-radius: 50%;" :src="comp_data.logo || 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/default.png'" width="100px" height="100px" alt="name" class="profileImg">
                                            <div v-if="loggedInUser.user_role == 'owner' || loggedInUser.user_role == 'super admin' || loggedInUser.user_role == 'super_admin'" class="edit-image pointer">
                                                <label for="input_profile_pic">
                                                    <img style="border-radius: 50%;" src="/static/images/editgreynew.svg">
                                                </label>
                                                <input id="input_profile_pic" class="v-hidden w-0" type="file" name="input_profile_pic" @change="uploadProfileAttachment" accept="image/*" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="px-4 py-2 mt-10">
                                <div class="pt-8">
                                    <div class="d-flex align-items-center justify-content-center">
                                        <div>
                                            <img style="border-radius: 50%;" :src="comp_data.logo || 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/default.png'" width="50px" height="50px" />
                                        </div>
                                        <div class="ml-5">
                                            <p class="fs-14 text-white fw-600">Business Profile</p>
                                            <p class="fs-14 text-white fw-600 mb-0 text-capitalize">{{comp_data.company_name || "-"}}</p>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="loggedInUser.user_role == 'owner' || loggedInUser.user_role == 'super admin' || loggedInUser.user_role == 'super_admin'" class="pt-8 px-8">
                                    <p class="fs-14 text-white fw-500 px-2">Company Profile {{comp_data.percentage_completed || 0}}% Complete</p>
                                    <div class="d-flex align-items-center justify-content-center">
                                        <div class="profile-complete-meter" :class="{'profile-complete-meter-filled':comp_data.percentage_completed > 0}">
                                        </div>
                                        <div class="profile-complete-meter ml-1" :class="{'profile-complete-meter-filled':comp_data.percentage_completed > 20}">
                                        </div>
                                        <div class="profile-complete-meter ml-1" :class="{'profile-complete-meter-filled':comp_data.percentage_completed > 40}">
                                        </div>
                                        <div class="profile-complete-meter ml-1" :class="{'profile-complete-meter-filled':comp_data.percentage_completed > 60}">
                                        </div>
                                        <div class="profile-complete-meter ml-1" :class="{'profile-complete-meter-filled':comp_data.percentage_completed > 80}">
                                        </div>
                                    </div>
                                    <el-carousel v-if="comp_data.to_be_filled.length > 0" trigger="click" :autoplay="false" class="view-comp-carousel" height="150px">
                                        <el-carousel-item v-for="(com_to_be_filled, index) in comp_data.to_be_filled" :key="index" >
                                            <div class="profile-complete-content pb-8 mx-2 mt-3 position-relative">
                                                <div class="position-absolute" style="right: 5px;top: 2px;">
                                                    <i @click="closeCarouselTab(index)" class="icon icon-close fs-20"></i>
                                                </div>
                                                <h2 class="text-secondary fs-13 fw-600 text-capitalize">{{com_to_be_filled}}</h2>
                                                <p class="fs-13" style="color: #707070;">Fill the {{com_to_be_filled}} information to add value to your Business Profile  </p>
                                            </div>
                                        </el-carousel-item>
                                    </el-carousel>
                                </div>
                                <div class="pt-8">
                                    <div class="d-flex align-items-center justify-content-center">
                                        <button class="btn btn-new-organge">Following</button>
                                        <button class="btn btn-new-success ml-2">Connected</button>
                                    </div>
                                </div> -->
                                <!-- <div class="pt-8">
                                    <div class="d-flex align-items-center justify-content-center connections-followers">
                                        <div class="px-4 text-center">
                                            <p class="fs-14 fw-600 text-white mb-1">24 k</p>
                                            <p class="fs-14 fw-500 text-white mb-0">Connections</p>
                                        </div>
                                        <div class="px-4 text-center" style="border-left: 1px solid #00448b; border-right: 1px solid #00448b;">
                                            <p class="fs-14 fw-600 text-white mb-1">120</p>
                                            <p class="fs-14 fw-500 text-white mb-0">Followers</p>
                                        </div>
                                        <div class="px-4 text-center">
                                            <p class="fs-14 fw-600 text-white mb-1">20 k</p>
                                            <p class="fs-14 fw-500 text-white mb-0">Following</p>
                                        </div>
                                    </div>
                                </div> -->
                                <!--div class="pt-8">
                                    <div class="row flex-wrap align-items-center">
                                        <div class="col-lg-4 col-sm-4 col-md-4 col-4 text-center">
                                            <div class="pointer" @click="goToParticularPage('company_info')" :class="{'access-tabs-border-active':(toShow == 'company_info') || (toShow == 'headquarters')}">
                                                <div class="text-center">
                                                    <img style="width: 100%;" src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/user_new.svg" />
                                                </div>
                                            </div>
                                            <span class="fs-10 text-white text-capitalize">Info</span>
                                        </div>
                                        <div class="col-lg-4 col-sm-4 col-md-4 col-4 text-center">
                                            <div class="access-tabs-border pointer">
                                                <div class="access-tabs text-center" @click="goToParticularPage('employees')">
                                                    <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/employees_new.svg" />
                                                </div>
                                            </div>
                                            <span class="fs-10 text-white text-capitalize">Employees</span>
                                        </div>
                                        <div class="col-lg-4 col-sm-4 col-md-4 col-4 text-center" @click="goToParticularPage('business_unit')">
                                            <div class="access-tabs-border pointer">
                                                <div class="text-center">
                                                    <img style="width: 100%;" src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/business_unit_new.svg" />
                                                </div>
                                            </div>
                                            <span class="fs-10 text-white text-capitalize">Business Units</span>
                                        </div>
                                        <div class="col-lg-4 col-sm-4 col-md-4 col-4 text-center" @click="goToParticularPage('department_unit')">
                                            <div class="access-tabs-border pointer">
                                                <div class="text-center">
                                                    <img style="width: 100%;" src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/department_new.svg" />
                                                </div>
                                            </div>
                                            <span class="fs-10 text-white text-capitalize">Departments</span>
                                        </div>
                                        <div class="col-lg-4 col-sm-4 col-md-4 col-4 text-center">
                                            <div class="access-tabs-border pointer" @click="goToParticularPage('product_access')">
                                                <div class="text-center">
                                                    <img style="width: 100%;" src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/product_new.svg" />
                                                </div>
                                            </div>
                                            <span class="fs-10 text-white text-capitalize">Product</span>
                                        </div>
                                        <div class="col-lg-4 col-sm-4 col-md-4 col-4 text-center">
                                            <div class="access-tabs-border pointer" :class="{'access-tabs-border-active':toShow == 'bankdetails'}" @click="goToParticularPage('bankdetails')">
                                                <div class="text-center">
                                                    <img style="width: 100%;" src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/bank_details_new.svg" />
                                                </div>
                                            </div>
                                            <span class="fs-10 text-white text-capitalize">Bank Details</span>
                                        </div>
                                        <div class="col-lg-4 col-sm-4 col-md-4 col-4 text-center">
                                            <div class="access-tabs-border pointer" :class="{'access-tabs-border-active':toShow == 'outlets'}" @click="goToParticularPage('outlets')">
                                                <div class="text-center">
                                                    <img style="width: 100%;" src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/outlet_new.svg" />
                                                </div>
                                            </div>
                                            <span class="fs-10 text-white text-capitalize">Outlets</span>
                                        </div>
                                        <div v-if="loggedInUser.user_role == 'owner' || loggedInUser.user_role == 'super admin' || loggedInUser.user_role == 'super_admin'" class="col-lg-4 col-sm-4 col-md-4 col-4 text-center">
                                            <div class="access-tabs-border pointer" @click="goToParticularPage('company_settings')">
                                                <div class="access-tabs text-center">
                                                    <img width="30" src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/settings-blue.png" />
                                                </div>
                                            </div>
                                            <span class="fs-10 text-white text-capitalize">Companies Setting</span>
                                        </div>
                                        <div class="col-lg-4 col-sm-4 col-md-4 col-4 text-center">
                                            <div class="access-tabs-border pointer" @click="goToParticularPage('consultant')">
                                                <div class="access-tabs text-center">
                                                    <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/consultant_new.svg" />
                                                </div>
                                            </div>
                                            <span class="fs-10 text-white text-capitalize">consultant</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="pt-8">
                                    <div class="text-center text-white fw-600 fs-14 pt-3 pb-8">
                                        <img src="/static/images/QR_Code_new.svg" width="100px" height="125px" alt="User Qr Code" />
                                        <div>User QR Code</div> --
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->

                    <div class="center-section w-100"
                        style="background-color:transparent !important;border:none !important;">
                        <div class="w-100" style="overflow-x: auto; overflow-y: hidden;border-radius: 6px 6px 0 0;">
                            <div v-if="toShow == 'outlets'" class="d-flex align-items-center pt-3" style="background-color: #fff;border-radius: 6px 6px 0 0;">
                                <div class='slant-tabs fs-14 d-flex align-items-center justify-content-center text-gray ml-0'
                                    :class="{'activeTabs':toShow == 'outlets','new-blue':toShow == 'outlets'}"
                                    @click="changeEditModelView('editoutletsview')" style="background-color: #fff;border-bottom: 3px solid #acaeb1;">
                                    Outlets
                                </div>
                            </div>
                            <div v-else-if="toShow == 'bankdetails'" class="d-flex align-items-center">
                                <div class='d-flex align-items-center justify-content-center text-gray ml-0'
                                    :class="{'activeTab':toShow == 'bankdetails','new-blue':toShow == 'bankdetails'}"
                                    @click="changeEditModelView('editbankdetailsview')">
                                    Bank Details
                                </div>
                            </div>
                            <div v-else class="d-flex align-items-center p-4" style="background-color: #fff;">
                                <div class='slant-tabs d-flex align-items-center justify-content-center text-gray fs-14 fw-600'
                                    :class="{'activeTab':toShow == 'company_info','new-blue':toShow == 'company_info'}"
                                    @click="changeEditModelView('editcompanyinfo')" style="background-color: #fff;border-bottom: 3px solid #acaeb1;">
                                    Company Info
                                </div>
                                <div class='slant-tabs d-flex align-items-center justify-content-center pl-4 pr-4 text-gray fs-14 fw-600'
                                    :class="{'activeTab':toShow == 'headquarters','new-blue':toShow == 'headquarters'}"
                                    @click="changeEditModelView('editheadquartersview')"  style="background-color: #fff;border-bottom: 3px solid #acaeb1;">
                                    Headquarter and Branches
                                </div>
                            </div>
                        </div>
                        <div class="center-content" style="margin-top: -20px;">
                            <div class="preview-elements">
                                <div v-if="toShow == 'outlets' || $route.query.outlet_view" class="tab-content profile-tab-content scroll-media overflow-auto-y" id="myTabContent">
                                    <div class="" id="basictab" aria-labelledby="basic-tab" v-if="outletsinfo">
                                        <view-outlets v-if="$route.query.outlet"></view-outlets>
                                        <outlets-info v-else >
                                        </outlets-info>
                                    </div>
                                </div>
                                <div v-else-if="toShow == 'bankdetails' || $route.query.bankdetails_view" class="tab-content profile-tab-content scroll-media overflow-auto-y" id="myTabContent">
                                    <div class="" id="basictab" aria-labelledby="basic-tab" v-if="bankdetailsinfo">
                                        <view-bank-details v-if="$route.query.bank"></view-bank-details>
                                        <bank-details-info v-else >
                                        </bank-details-info>
                                    </div>
                                </div>
                                <div v-else class="tab-content profile-tab-content scroll-media" id="myTabContent">
                                    <div class="" id="basictab" aria-labelledby="basic-tab" v-if="companyinfo">
                                        <company-info @editValue="enabledEdit">
                                        </company-info>
                                    </div>
                                    <div class="" id="basictab" aria-labelledby="basic-tab" v-if="headquartersinfo">
                                        <create-branch v-if="$route.query.branch"></create-branch>
                                        <headquarter-and-branches v-else ></headquarter-and-branches>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <go-back-popup @hideModal="hideModals" @goBack="screenBack" v-if="goBackModel" modal_name="Go_Back"></go-back-popup>
        <companyinfo-confirm-popup @hideModal="hideModal" @goBack="goBack" v-if="confirmPopup" modal_name="Confirm-Popup"></companyinfo-confirm-popup>
        <create-branch v-if="create_branch" @hideCreateBranch="hideCreateBranch" modal_name="create-branch"></create-branch>
    </div>
</template>
<script>
    import {
        EventBus
    } from "../eventBus/nav-bar-event.js";
    import CompanyInfo from "./CompanyInfo";
    import BankDetailsInfo from "./BankDetailsInfo";
    import HeadquarterAndBranches from "./HeadquarterAndBranches";
    import ViewBranch from "./Branch/ViewBranch";
    import ViewOutlets from "./Outlets/ViewOutlets";
    import ViewBankDetails from "./BankDetails/ViewBankDetails";
    import OutletsInfo from "./OutletsInfo";
    import companies from '../mixins/companies.js';
    import {HalfCircleSpinner} from 'epic-spinners';
    const CreateBranch = () => import('./Branch/CreateBranch');
    import CompanyinfoConfirmPopup from './CompanyInfoConfirmPopup'
    import axios from 'axios';
    import GoBackPopup from './Branch/GoBackPopup'

    export default {
        data() {
            return {
                edit_value: false,
                enable: false,
                create_branch: false,
                requestResponse: false,
                companyinfo: true,
                outletsinfo: false,
                remindersInformation: false,
                headquartersinfo: false,
                branchinfo: false,
                bankdetailsinfo: false,
                productAccess: false,
                toShow: "company_info",
                profile_attachment: {},
                cover_attachment: {},
                comp_data: {
                    company_name: '',
                    cover_image: '',
                    logo: '',
                    percentage_completed: 0,
                    to_be_filled: []
                },
                ajax_call_in_progress: false,
                confirmPopup: false,
                goBackModel:false,
                organization_for_company: ""
            };
        },
        mixins: [companies],

        components: {
            CompanyInfo,
            ViewBranch,
            BankDetailsInfo,
            OutletsInfo,
            HeadquarterAndBranches,
            HalfCircleSpinner,
            ViewOutlets,
            ViewBankDetails,
            CreateBranch,
            CompanyinfoConfirmPopup,
            GoBackPopup
            
        },
        methods: {
           hideModals() {
                this.goBackModel = false;
            },
            screenBack() {
                this.toShow = 'company_info';
                this.companyinfo = true;
                this.headquartersinfo = false;
                this.branchinfo = false;
                this.bankdetailsinfo = false;
                this.outletsinfo = false;
                // window.history.back();
            },
            enabledEdit(edit_enable){
                this.edit_value = edit_enable
            },
            hideModal() {
                this.confirmPopup = false;
            },
            goBack() {
                this.$route.query.branch = ''
                this.toShow = 'headquarters';
                this.companyinfo = false;
                this.headquartersinfo = true;
                this.branchinfo = false;
                this.bankdetailsinfo = false;
                this.outletsinfo = false;
                this.edit_value = false;
            },
            enableEdit(){
                this.enable = true;
                EventBus.$emit('enableEdit', this.enable);
            },
            hideCreateBranch() {
                this.create_branch = false;
                this.getListViewData();
            },
            showCreateBranch() {
                this.create_branch = true;
                setTimeout(() => {
                    this.$modal.show('create-branch');
                }, 500);
            },
            goToCompanyInfo() {
                this.goToParticularPage('company_info')
            },
            goToListView() {
                jQuery('.bigcontainer').animate({scrollTop:0},0);
                if(this.loggedInUser.has_organization_or_standalone) {
                    if(this.loggedInUser.user_role == 'owner') {
                        if(this.loggedInUser.user_has == 'organization') {
                            this.$router.push('/organization/listview');
                        } else if(this.loggedInUser.user_has == 'standalone') {
                            this.$router.push('/company/listview');
                        }
                    } else {
                        this.$router.push('/organization/listview');
                    }
                } else {
                    this.$router.push('/company/createcompanyororganization');
                }
            },
            goToParticularPage(type) {
                jQuery('.bigcontainer').animate({scrollTop:0},0);
                if(type == 'company_settings') {
                    this.$router.push('/company/settings/cmpid/' + this.$route.params.company_id);
                } else if(type == 'outlets') {
                    this.toShow = 'outlets';
                    this.$router.push({ path: "/company/view/cmpid/"+this.$route.params.company_id, query: {outlet_view: 'view'} })
                    this.outletsinfo = true;
                } else if (type == 'bankdetails') {
                    this.toShow = 'bankdetails';
                    this.$router.push({ path: "/company/view/cmpid/"+this.$route.params.company_id, query: {bankdetails_view: 'view'} })
                    this.bankdetailsinfo = true;
                } else if (type == 'company_info') {
                    this.toShow = 'company_info';
                    this.$router.push({ path: "/company/view/cmpid/"+this.$route.params.company_id})
                    this.companyinfo = true;
                } else if (type == 'business_unit') {
                    this.$router.push('/businessunit/listview/cmpid/' + this.$route.params.company_id);
                } else if (type == 'department_unit') {
                    this.$router.push('/departmentunit/listview/cmpid/' + this.$route.params.company_id);
                } else if (type == 'employees') {
                    this.$router.push('/employee/listemployee/cmpid/' + this.$route.params.company_id);
                } else if (type == 'product_access') {
                    this.$router.push('/product/view/cmpid/' + this.$route.params.company_id);
                }
                else if (type == 'consultant'){
                    this.$router.push('/consultants/listview/cmpid/'+this.$route.params.company_id)
                }
            },
            closeCarouselTab(index) {
                this.comp_data.to_be_filled.splice(index, 1)
            },
            uploadCoverAttachment(e) {
                var input = event.target;
                if (input.files && input.files[0]) {
                    const CancelToken = axios.CancelToken;
                    const source = CancelToken.source();
                    let attachment = {
                        name: input.files[0].name,
                        size: JSON.stringify(input.files[0].size),
                        source: source,
                        progress: 0,
                        created_time: new Date(),
                        error: "",
                    };
                    this.getResumableURLprofile(input.files[0], attachment, "cover_pic");
                }
            },
            // User Profile Image Change Function
            uploadProfileAttachment(e) {
                var input = event.target;
                if (input.files && input.files[0]) {
                    const CancelToken = axios.CancelToken;
                    const source = CancelToken.source();
                    let attachment = {
                        name: input.files[0].name,
                        size: JSON.stringify(input.files[0].size),
                        source: source,
                        progress: 0,
                        created_time: new Date(),
                        error: "",
                    };
                    this.getResumableURLprofile(input.files[0], attachment, "profile_pic");
                }
            },
            getResumableURLprofile(file, attachment, type) {
                let data = {
                    file: attachment.name,
                };
                data = new FormData();
                data.append("file", file);
                this.$http
                    .post("https://cloudapis.digicollect.com/external/upload_file", data)
                    .then(response => {
                        if (response.data.status_id == 1) {
                            this.updateInfo(type, response.data.url);
                        }
                    })
                    .catch(error => {
                        // this.error = "Error Uploading"
                    });
            },
            async updateInfo(type, url) {
                try {
                    let params = {
                    };
                    if (type == 'cover_pic') {
                        params.cover_image = url;
                        let response = await this.editCompany(params, this.$route.params.company_id);
                        if(response.status_id == 1) {
                            this.comp_data.cover_image = url;
                        }
                    } else if (type == 'profile_pic') {
                        params.logo = url;
                        let response = await this.editCompany(params, this.$route.params.company_id);
                        if(response.status_id == 1) {
                            this.comp_data.logo = url;
                        }
                    }
                }
                catch(err) {
                    // console.log(err);
                }
            },
            changeEditModelView(editName) {
                if (editName == "editcompanyinfo") {
                    if(this.$route.query.branch){
                        this.goBackModel = true
                        setTimeout(() => {
                            this.$modal.show('Go_Back')
                        }, 500)
                    }else{
                        this.toShow = 'company_info';
                        this.companyinfo = true;
                        this.headquartersinfo = false;
                        this.branchinfo = false;
                        this.bankdetailsinfo = false;
                        this.outletsinfo = false;
                    }
                } else if (editName == "editheadquartersview") {
                    if(this.edit_value == true){
                        this.confirmPopup = true;
                        setTimeout(() => {
                            this.$modal.show('Confirm-Popup')
                        }, 500)
                    }else{
                        this.$route.query.branch = ''
                        this.toShow = 'headquarters';
                        this.companyinfo = false;
                        this.headquartersinfo = true;
                        this.branchinfo = false;
                        this.bankdetailsinfo = false;
                        this.outletsinfo = false;
                    }
                } else if (editName == "editbranchview") {
                    this.toShow = 'branch';
                    this.companyinfo = false;
                    this.headquartersinfo = false;
                    this.branchinfo = true;
                    this.bankdetailsinfo = false;
                    this.outletsinfo = false;
                } else if (editName == "editbankdetailsview") {
                    if(this.enable = true) {
                        this.toShow = 'bankdetails';
                        this.companyinfo = false;
                        this.headquartersinfo = false;
                        this.branchinfo = false;
                        this.bankdetailsinfo = true;
                        this.outletsinfo = false;
                    }
                } else if (editName == "editoutletsview") {
                    this.toShow = 'outlets';
                    this.companyinfo = false;
                    this.headquartersinfo = false;
                    this.branchinfo = false;
                    this.bankdetailsinfo = false;
                    this.outletsinfo = true;
                } else if (editName == "editRemindersView") {
                    this.toShow = 'reminders';
                    this.companyinfo = false;
                    this.headquartersinfo = false;
                    this.branchinfo = false;
                    this.bankdetailsinfo = false;
                    this.outletsinfo = false;
                } else if (editName == "editProductAccess") {
                    this.toShow = 'products';
                    this.companyinfo = false;
                    this.headquartersinfo = false;
                    this.branchinfo = false;
                    this.bankdetailsinfo = false;
                    this.outletsinfo = false;
                }
            },
            async getCompData() {
                this.ajax_call_in_progress = true;
                try {
                    let response = await this.getCompanyById(this.$route.params.company_id);
                    if (response.status_id == 1) {
                        let res_data = response.response
                        this.comp_data.company_name = res_data.name
                        this.comp_data.logo = res_data.logo
                        this.comp_data.cover_image = res_data.cover_image;
                        this.comp_data.percentage_completed = response.percentage_completed;
                        this.comp_data.to_be_filled = response.to_be_filled;
                    }
                    this.ajax_call_in_progress = false;
                }
                catch(err) {
                    // console.log(err)
                }
            },
        },
        beforeRouteLeave (to, from, next) {
            if (this.edit_value == true || this.$route.query.branch) {
                    let message = "<img src='/static/images/exclamation-yellow.svg' style='width: 55px;margin-bottom: 15px;' /><br><div class='d-block'>Unsaved changes would be lost, Do you want to proceed?</div>";
                    let options = {
                    html: true,
                    loader: false,
                    reverse: false, // switch the button positions (left to right, and vise versa)
                    okText: 'Yes',
                    cancelText: 'No',
                    animation: 'fade',
                    type: 'basic',
                    verification: 'continue', // for hard confirm, user will be prompted to type this to enable the proceed button
                    verificationHelp: 'Type "[+:verification]" below to confirm', // Verification help text. [+:verification] will be matched with 'options.verification' (i.e 'Type "continue" below to confirm')
                    backdropClose: true,
                };
                this.$dialog.confirm(message, options)
                .then(function () {
                    next();
                })
                .catch(function () {
                    next(false);
                });
            } else {
                next()
            }
        },
        created() {
        },
        mounted() {
            this.organization_for_company = this.$route.params.org_name
            if(this.organization_for_company == '' || this.organization_for_company == undefined || this.organization_for_company == null){
                this.organization_for_company = ''
            }
            this.getCompData();
            if(this.$route.query.branch) {
                this.toShow = "headquarters";
                this.companyinfo = false;
                this.outletsinfo = false;
                this.headquartersinfo = true;
                this.bankdetailsinfo = false;
            }  else if(this.$route.query.createbranch) {
                this.toShow = "headquarters";
                this.companyinfo = false;
                this.outletsinfo = false;
                this.headquartersinfo = true;
                this.bankdetailsinfo = false;
            }else if(this.$route.query.outlet) {
                this.toShow = "outlets";
                this.companyinfo = false;
                this.headquartersinfo = false;
                this.outletsinfo = true;
                this.bankdetailsinfo = false;
            } else if(this.$route.query.bank) {
                this.toShow = "bankdetails";
                this.companyinfo = false;
                this.headquartersinfo = false;
                this.outletsinfo = false;
                this.bankdetailsinfo = true;
            }
            this.getCompData();
            if(this.$route.query.outlet_view == 'view') {
                this.toShow = "outlets";
                this.companyinfo = false;
                this.headquartersinfo = false;
                this.outletsinfo = true;
                this.bankdetailsinfo = false;
            } else if(this.$route.query.bankdetails_view == 'view') {
                this.toShow = "bankdetails";
                this.companyinfo = false;
                this.headquartersinfo = false;
                this.outletsinfo = false;
                this.bankdetailsinfo = true;
            }
        },
    //     destroyed() {
    //     EventBus.$off("organization_name");
    // },

        // Adding Store Permissions
        computed: {
            loggedInUser() {
                return this.$store.getters.loggedInUser;
            }
        },
    };
</script>

<style scoped>
    .profile-complete-meter {
        border: solid 0.5px #fff;
        height: 5px;
        width: 50px;
        background-color: transparent;
    }
    .profile-complete-meter-filled {
        background-color: #fff;
    }
    .profile-complete-content {
        padding: 10px;
        border-radius: 5px;
        box-shadow: 0 0 3px 0 #939393;
        background-color: #fff;
    }
    .activeTab{
        border-bottom: 3px solid #00448b !important;
    }
    .activeTabs{
        border-bottom: 3px solid #fff !important;
    }
    .btn-new-primary {
        color: #fff;
        border-radius: 5px;
        padding-left: 20px !important;
        padding-right: 20px !important;
    }
</style>